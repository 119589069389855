import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`After spending a long time looking for an internship, I got desperate enough to
start applying for some non-paid ones. In my second interview, I finally landed
a gig at the `}<a parentName="p" {...{
        "href": "https://www.ctg.albany.edu/"
      }}>{`Center for Technology in Government`}</a>{`. Sounds pretty exciting,
I know...`}</p>
    <h2>{`alumni map`}</h2>
    <p>{`But actually, it kinda was. Despite the fact that this was a free internship,
I was pretty pumped to finally have a job in tech. I was able to immediately
dive into web development with my first project, an interactive alumni map.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8d303aa7dcd04f92f26a8ef333dd9262/644c5/ctg-alumni-map.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwL/2gAMAwEAAhADEAAAAdtQQabkL//EABoQAAICAwAAAAAAAAAAAAAAAAABETECAxT/2gAIAQEAAQUCbkxUrn1ipWf/xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQMBAT8BdZ//xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQIBAT8BMJ//xAAXEAADAQAAAAAAAAAAAAAAAAAAARAy/9oACAEBAAY/Apkd/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERITFBsf/aAAgBAQABPyHkwTor4JNehoB4EH//2gAMAwEAAgADAAAAECcP/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIUH/2gAIAQMBAT8QVDaQj//EABkRAAIDAQAAAAAAAAAAAAAAAAABESFBUf/aAAgBAgEBPxB2JhL6f//EABkQAQADAQEAAAAAAAAAAAAAAAEAESFhcf/aAAgBAQABPxBLI6yURuw8QCiAw1GQOlRjoNzfYEKFon//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "alumni map",
            "title": "alumni map",
            "src": "/static/8d303aa7dcd04f92f26a8ef333dd9262/644c5/ctg-alumni-map.jpg",
            "srcSet": ["/static/8d303aa7dcd04f92f26a8ef333dd9262/158ba/ctg-alumni-map.jpg 360w", "/static/8d303aa7dcd04f92f26a8ef333dd9262/80e3c/ctg-alumni-map.jpg 720w", "/static/8d303aa7dcd04f92f26a8ef333dd9262/644c5/ctg-alumni-map.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I was given the source of another page using `}<a parentName="p" {...{
        "href": "https://leafletjs.com"
      }}>{`leaflet`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.mapbox.com/"
      }}>{`mapbox`}</a>{` and
basically told "hey, we need something like this". So I dove into the 1000+
line javascript file and started picking it apart piece by piece.`}</p>
    <p>{`Previously, I had had a little exposure to javascript through school and
`}<a parentName="p" {...{
        "href": "https://www.codecademy.com/"
      }}>{`codecademy`}</a>{` but still really didn't know what I was doing. I had focused
mostly on HTML and CSS up until this point in part because of my design
background, but also an irrational fear of real programming. Diving into this
source code was really my first exposure to real functions, event handling,
and native js mixed with a few libraries.`}</p>
    <p>{`It took me a couple months too build (way too long), but in the end it came out
pretty well. They kept it up on their site for three or four years but it seems
they took it down recently (as of 2019).`}</p>
    <h2>{`mta dashboards`}</h2>
    <p>{`This project was a bit longer and involved some cooler uses of data. In short,
we were given access to a few hundred thousand datapoints from the `}<a parentName="p" {...{
        "href": "https://new.mta.info/"
      }}>{`MTA`}</a>{` and
told experiment with various visualizations and dashboards. The following
screenshot was one of the first I sketched out and did the charting work for:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fc60e9b4ba6ff574a10f6f7579a6235a/644c5/ctg-mta-dash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.55555555555556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdFFLSAVg//EABwQAAEEAwEAAAAAAAAAAAAAAAEAAgMRBBIhI//aAAgBAQABBQLGGraCki9GqyiO/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAABBQEAAAAAAAAAAAAAAAARAQIQIDFx/9oACAEBAAY/AgNWHdp//8QAGxAAAgMAAwAAAAAAAAAAAAAAAAERITFBYXH/2gAIAQEAAT8hvuiz2JHxyFSVLDuENnB//9oADAMBAAIAAwAAABAn3//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/EIf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCn/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITGBkeHw/9oACAEBAAE/EBG5ecrKCBbtz7GAwWO5YgFWY1PgRLBVVn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mta dashboard",
            "title": "mta dashboard",
            "src": "/static/fc60e9b4ba6ff574a10f6f7579a6235a/644c5/ctg-mta-dash.jpg",
            "srcSet": ["/static/fc60e9b4ba6ff574a10f6f7579a6235a/158ba/ctg-mta-dash.jpg 360w", "/static/fc60e9b4ba6ff574a10f6f7579a6235a/80e3c/ctg-mta-dash.jpg 720w", "/static/fc60e9b4ba6ff574a10f6f7579a6235a/644c5/ctg-mta-dash.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This was my first exposure to `}<a parentName="p" {...{
        "href": "https://d3js.org/"
      }}><inlineCode parentName="a">{`d3`}</inlineCode></a>{` and it took me down a serious wormhole.
For those of you who aren't familiar with it, `}<inlineCode parentName="p">{`d3`}</inlineCode>{` is a low-level JavaScript
framework for constructing interactive charts in the browser. And when I say
"low-level", I mean like it allows you to build almost any chart you can think
of, transition one visualization into another, and even come up with things
that aren't out there yet. The downside of this is that it takes some time to
learn.`}</p>
    <p>{`We did a bunch more dashboards like the one above for this project, but even
then we still barely scratched the surface. Luckily, I've gotten to experiment
with and utilize `}<inlineCode parentName="p">{`d3`}</inlineCode>{` at my following jobs.`}</p>
    <p>{`This project was a lot of fun and it was cool to work with an agency like the
MTA who have done some pretty cool visualizations on their own.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      